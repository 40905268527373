/** @deprecated Use the spacing enum instead */
export const spacingXXS = "0.25rem"
/** @deprecated Use the spacing enum instead */
export const spacingXS = "0.5rem"
/** @deprecated Use the spacing enum instead */
export const spacingS = "0.75rem"
/** @deprecated Use the spacing enum instead */
export const spacingM = "1rem"
/** @deprecated Use the spacing enum instead */
export const spacingL = "1.5rem"
/** @deprecated Use the spacing enum instead */
export const spacingXL = "2rem"
/** @deprecated Use the spacing enum instead */
export const spacingXXL = "2.5rem"
/** @deprecated Use the spacing enum instead */
export const spacingXXXL = "3rem"
/** @deprecated Use the spacing enum instead */
export const spacingXXXXL = "4rem"
/** @deprecated Use the spacing enum instead */
export const spacingXXXXXL = "6rem"
/** @deprecated Use the spacing enum instead */
export const spacingXXXXXXL = "8rem"
/** @deprecated Use the spacing enum instead */
export const spacingXXXXXXXL = "16rem"

export enum spacing {
  XS3 = "0.125rem",
  XS2 = "0.25rem",
  XS = "0.5rem",
  S = "0.75rem",
  M = "1rem",
  L = "1.5rem",
  XL = "2rem",
  XL2 = "2.5rem",
  XL3 = "3rem",
  XL4 = "4rem",
  XL5 = "6rem",
  XL6 = "8rem",
  XL7 = "16rem",
}
